import { ChangeDetectorRef, Component, ElementRef, ViewChild, inject } from '@angular/core';
import { FormBuilder, FormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { NZ_MODAL_DATA } from 'mwc/modal';
import { MwcModalRef, MwcModalService } from 'mwc/modal';
import { topicIdea } from '../_shared/models/topic';
import { TopicIdeaPayloadForAIGeneration, TopicIdeaPayloadForAIGenerationType } from '../_shared/models/TopicIdeaPayloadForAIGeneration';
import { ArticleService } from '../services/article/article.service';
import { OpenAIContextResponseType } from '../_shared/models/open-ai';
import { ArticleHelperService } from '../_shared/services/helper/article/article-helper.service';
import { ErrorModalOrigins, KeywordConstraint } from '../_shared/consts/article-constants';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Article, BrandVoice, BrandVoiceThingJson, CustomArticleErrorModel, DefaultBrandVoiceForProfileRequest, DefaultTargetAudienceForProfileRequest, DocumentDetail, DocumentLanguage, GetBrandVoiceListRequest, GetBrandVoiceListResponse, GetTargetAudienceListRequest, GetTargetAudienceListResponse, PromptProperties, TargetAudience } from '../_shared/models/article';
import { ArticleStoreService } from '../services/article/article-store.service';
import { TopicService } from '../services/topic/topic.service';
import { GetAudiencesRequest } from '../_shared/models/GetAudiencesRequest';
import { StorageHelper } from '../_utilities/storage-helper';
import { GetAudiencesResponseType } from '../_shared/models/GetAudiencesResponse';
import { GoogleKeywordPlannerResponseType } from '../_shared/models/google-keyword-planner';
import { SelectYourKeywordComponent } from '../manage-content/articles/select-your-keyword/select-your-keyword.component';
import { UpdateAudienceRequest } from '../_shared/models/UpdateAudienceRequest';
import { SettingsService } from '../services/settings/settings.service';
import { MwcMessageService } from 'mwc/message';
import { ContentStudioTrackingService } from '../_shared/services/tracking/contentstudio-tracking.service';
import { CmsService } from '../services/cms/cms.service';
import { TemplateHelperService } from '../_shared/services/helper/template-selector/template-helper.service';
import { CommonService } from '../services/common/common.service';
import { FEATURES } from '../_shared/consts/app-constants';
import { UserInfo } from '../_shared/models/common';
import { GetSettingsFileRequest } from '../_shared/models/getsettingsfilerequest';
import { GetSettingsFileResponse } from '../_shared/models/getsettingsfileresponse';

const ageList = [{ label: "All", value: "all" }, { label: 'Children (Below 13)', value: '0-13' }, { label: 'Adolescents (13 to 17)', value: '13-17' }, { label: 'Growing Adults (18 to 24)', value: '18-24' }, { label: 'Young Adults (25-34)', value: '25-34' }, { label: 'Older Adults (35 to 50)', value: '35-50' },{ label: 'Mature Adults (Above 50)', value: '50+' }];

@Component({
  selector: 'app-genarate-ai',
  templateUrl: './genarate-ai.component.html',
  styleUrls: ['./genarate-ai.component.scss']
})
export class GenarateAiComponent {
  constructor(private fb: FormBuilder, private modalService: MwcModalService, private modal: MwcModalRef, public cdRef: ChangeDetectorRef, private articleService: ArticleService,
    private articleHelperService: ArticleHelperService, private router: Router, private articleStoreService: ArticleStoreService, private topicService: TopicService, private settingsService: SettingsService, private message: MwcMessageService, private tracking: ContentStudioTrackingService
    , private cms: CmsService,
    private templateHelperService: TemplateHelperService,
    private commonService: CommonService) {
    this._storageHelper = new StorageHelper();
  }


  generateAiForm: FormGroup | undefined;
  readonly mwcModalData: { AIData: TopicIdeaPayloadForAIGenerationType } = inject(NZ_MODAL_DATA);

  currentSelectedTopicData: topicIdea = this.mwcModalData.AIData.topicIdea;
  isLoading = false;
  toneTypes: string[] = [];
  selectedValueLanguage = this.mwcModalData.AIData.language && this.mwcModalData.AIData.language !== 'null' ? this.mwcModalData.AIData.language : "US";
  selectedValueTone = this.mwcModalData.AIData.tone && this.mwcModalData.AIData.tone !== 'null' ? this.mwcModalData.AIData.tone : "Informative";
  selectedValueGender = this.mwcModalData.AIData.gender && this.mwcModalData.AIData.gender !== 'null' ? this.mwcModalData.AIData.gender : "All";
  selectedValueAgeGroup = this.mwcModalData.AIData.ageGroup && this.mwcModalData.AIData.ageGroup !== 'null' ? this.mwcModalData.AIData.ageGroup : "All";
  selectedValueInterestAndPersona = this.mwcModalData.AIData.interestAndPersona && this.mwcModalData.AIData.interestAndPersona !== 'null' ? this.mwcModalData.AIData.interestAndPersona : "All";
  selectedTemplateTitle = this.mwcModalData.AIData.templateTitle;
  selectedTemplateId = this.mwcModalData.AIData.templateId;
  inputText = this.mwcModalData.AIData.inputText;
  titleText = this.mwcModalData.AIData.titleText;
  placeholderText = this.mwcModalData.AIData.placeholderText;
  contentBriefText = this.mwcModalData.AIData.contentBriefText;
  isPublishable = this.mwcModalData.AIData.isPublishable;
  isadvancedSettingsShow = false;
  isContentBriefEnabled = false;
  _storageHelper: StorageHelper;
  //Selected Keywords
  selectedKeywords = [];
  keywordInputVisible = false;
  selectedKeywordInputValue = '';
  @ViewChild('inputElement', { static: false }) inputElement?: ElementRef;
  toneData = [{ label: 'Informative', value: 'Informative' },
  { label: 'Persuasive', value: 'Persuasive' },
  { label: 'Inspirational', value: 'Inspirational' },
  { label: 'Educational', value: 'Educational' },
  { label: 'Storytelling', value: 'Storytelling' },
  { label: 'Formal', value: 'Formal' },
  { label: 'Conversational', value: 'Conversational' }];

  provinceData = [
    //{ id: 30, label: "Arabic", languageCode: "ar", value: "SA" } Saudi Arabia
    { id: 12, label: "English", languageCode: "en", value: "US" }, // United States
    { id: 192, label: "English(UK)", languageCode: "gb", value: "GB" }, // English(UK)
    { id: 16, label: "French", languageCode: "fr", value: "FR" }, // France
    { id: 15, label: "German", languageCode: "de", value: "DE" }, // Germany
    { id: 11, label: "Hindi", languageCode: "hi", value: "IN" }, // India
    { id: 86, label: "Indonesian", languageCode: "id", value: "ID" }, // Indonesia
    { id: 29, label: "Japanese", languageCode: "ja", value: "JP" }, // Japan
    { id: 18, label: "Korean", languageCode: "ko", value: "KR" }, // South Korea
    { id: 190, label: "Mandarin", languageCode: "zh-CN", value: "CN" }, // China
    { id: 9, label: "Portuguese", languageCode: "pt", value: "PT" }, // Portugal
    //{ id: 26, label: "Portuguese (Mexico)", languageCode: "pt-mx", value: "PT" }, // Brazil
    { id: 8, label: "Russian", languageCode: "ru", value: "RU" }, // Russia
    { id: 17, label: "Spanish", languageCode: "es", value: "ES" }, // Spain
    { id: 32, label: "Spanish (Mexico)", languageCode: "es-mx", value: "MX" }, // Mexico
    { id: 31, label: "Swahili", languageCode: "sw", value: "KE" }, // Kenya
    { id: 170, label: "Turkish", languageCode: "tr", value: "TR" }, // Turkey
    { id: 25, label: "Vietnamese", languageCode: "vi", value: "VN" }, // Vietnam
    //{ id: 63, label: "Chinese", languageCode: "zh", value: "CN" } //Chinese
  ];

  //provinceData = [{ label: 'English (US)', value: 'US' }, { label: 'English (UK)', value: 'GB' }, { label: 'Spanish', value: 'ES' }, { label: 'French', value: 'FR' }, { label: 'German', value: 'DE' }, { label: 'Portugese', value: 'PT' }];
  genderList = [{ label: 'All', value: 'All' },
  { label: 'Male', value: 'Male' },
  { label: 'Female', value: 'Female' },
  { label: 'Binary (Male or Female)', value: 'Binary' },
  { label: 'Non-Binary (Neither Male Neither Female)', value: 'Non-Binary' }];
  ageGroupList = [
    { label: "All", value: "All" },
    { label: 'Children (Below 13)', value: 'Children' },
    { label: 'Adolescents (13 to 17)', value: 'Adolescents' },
    { label: 'Growing Adults (18 to 24)', value: 'Growing Adults' },
    { label: 'Young Adults (25-34)', value: 'Young Adults' },
    { label: 'Older Adults (35 to 50)', value: 'Older Adults' },
    { label: 'Mature Adults (Above 50)', value: 'Mature Adults' }];
  interestsPersonalsList = [{ label: "All", value: "All" }];;
  selectedValueAIEngine = "_openAI";
  AIEngine = [
    { label: "Open AI", value: "_openAI" },
    { label: "Google Vertex AI", value: "_googleVertexAI" }
  ];
  isGenericFlow = this.mwcModalData.AIData.isGenericFlow ? this.mwcModalData.AIData.isGenericFlow : false;
  personaEnabled: boolean = false;
  addedPersona: any;
  isContentIntelligenceEnabled: boolean
  contentBriefPlaceholder =
    `Share a prompt explaining any additional content relevancy you would like your article to reference. Max 100 words.`;
  maxKeywordsAllowed: number = KeywordConstraint.MAX_KEYWORDS;
  retryCount = 0;
  maxRetryCount = 4;
  //contentTextData = { titleText: "", placeholderText: "", contentBriefText: "" };
  isNoCreateBrandVoice:boolean = true;
  brandVoiceList = [];
  filteredBrandVoiceList = [];
  selectedBrandVoice:string;
  @ViewChild('contentTextarea') contentTextarea!: ElementRef<HTMLTextAreaElement>;
  brandVoiceDetails: { label: string, value: string, tone: string, createdOn: string, createdBy: string, brandVoiceDocument: string, details :BrandVoiceThingJson};
  isExpanded = false;

  
  isNoTargetAudience:boolean = true;
  selectedTargetAudience:string;
  targetAudienceList = [];
  targetAudienceDetails:TargetAudience;
  
  isRedirectToMPC = false;
  modalType: string;
  brandVoiceUrl: string;
  targetAudienceUrl: string;
  planType: string = 'lite-users';
  hasKeywordPlanner: boolean = true;
  isSaaSUser: boolean = true;
  isDefaultBVAvailable: boolean = false;
  defaultBVId: number = 0;
  isDefaultTAAvailable: boolean = false;
  defaultTAId: number = 0;

  ngOnInit() {
    this.targetAudienceUrl = this._storageHelper.user.returnUrl + this._storageHelper.user.targetAudienceUrl;
    this.brandVoiceUrl = this._storageHelper.user.returnUrl + this._storageHelper.user.brandVoiceUrl;

    this.commonService.getUserInfoValue().subscribe((res: UserInfo) => {
      if (res != null) {
        this.isSaaSUser = res.isSaasUser;
        this.hasKeywordPlanner = res.featureIds.includes(FEATURES.KeyWordPlannerFeatureName);
      }
    });

    // for keywords
    this.currentSelectedTopicData.keywords = this.currentSelectedTopicData.keywords ? this.currentSelectedTopicData.keywords.map(keyword => keyword.toLowerCase()) : [];
    this.selectedKeywords = this.currentSelectedTopicData.keywords ? this.currentSelectedTopicData.keywords.slice(0, 6) : [];
    // for other controls
    this.generateAiForm = this.fb.group({
      description: [this.currentSelectedTopicData.pillarIdea ? this.toTitleCase(this.currentSelectedTopicData.pillarIdea) : ''],
      contentBrief: new UntypedFormControl(),
      tone: new UntypedFormControl(this.selectedValueTone),
      language: new UntypedFormControl(this.selectedValueLanguage, []),
      gender: new UntypedFormControl(this.selectedValueGender),
      ageGroup: new UntypedFormControl(this.selectedValueAgeGroup),
      interestsPersonals: new UntypedFormControl(this.selectedValueInterestAndPersona),
      aiEngine: new UntypedFormControl("_openAI", []),
      coreTopic: [this.currentSelectedTopicData.pillarIdea ? this.toTitleCase(this.currentSelectedTopicData.coreTopic) : ''],
      brandVoice: this.selectedBrandVoice,
      brandVoiceDocument: "",
      brandVoiceDetails: null,
      brandVoiceId: 0,
      targetAudience: this.selectedTargetAudience,
      targetAudienceId: 0,
      targetAudienceDetails:null
    });
    // if(!this.isGenericFlow){
    //   this.generateAiForm.controls["description"].disable();
    // }
    // fill interest and persona === targetAudience from API
    this.FillInterestAndPersona();
    this.articleService.IsContentIntelligenceEnabledObjectObservable.subscribe(res => {
      this.isContentIntelligenceEnabled = res;
    });
    //this.cms.getCmsSiteConfig().then(siteConfig => {
    //  if (siteConfig && siteConfig.mlTranslationEnableLangauges && siteConfig.mlTranslationEnableLangauges.length > 0) {
    //    const langCode = siteConfig.mlTranslationEnableLangauges[0].languageCode;
    //    if (langCode) {
    //      const matchingProvince = this.provinceData.find(ele => ele.languageCode === langCode);
    //      this.selectedValueLanguage = matchingProvince ? matchingProvince.value : '';
    //    }
    //  }
    //}).catch(error => {
    //  console.error('Error fetching CMS site configuration:', error);
    //});

    this.fetchBrandVoices();
    this.fetchTargetAudiences();
    //this.contentTextData = this.UpdateTextByContent(this.selectedTemplateTitle);
    this.updateTextareaScroll();
  }

  fetchBrandVoices(isReload: boolean=false) {
    if (isReload) { this.filteredBrandVoiceList = []; this.brandVoiceList = []; }
    var brandVoiceListRequest = new GetBrandVoiceListRequest();
    brandVoiceListRequest.profileId = +this._storageHelper.user.pId;

    this.isLoading = true;
    this.articleService.GetBrandVoiceList(brandVoiceListRequest).subscribe((response: GetBrandVoiceListResponse) => {
      console.log(response);
      if (response.errorCode != 0 && !response.errorMessage) {
        //show error messages
      } else {
        if (response.totalRecords > 0) this.isNoCreateBrandVoice = false;
        response.brandVoices.forEach((brandVoice: BrandVoice) => {
          if (brandVoice.isDefault) {
            this.isDefaultBVAvailable = true;
            this.defaultBVId = brandVoice.brandVoiceId;
          }
          this.brandVoiceList.push({
            label: brandVoice.title, value: brandVoice.brandVoiceId.toString(), tone: brandVoice.thingBrandVoice.thingJson.voiceTone, createdOn: brandVoice.createdDate.toString(), createdBy: brandVoice.authorName, brandVoiceDocument: brandVoice.thingBrandVoice.thingJson.brandVoiceDocument, details: brandVoice.thingBrandVoice.thingJson
          })
        });
        this.filteredBrandVoiceList = this.brandVoiceList;
        if (this.isDefaultBVAvailable && this.defaultBVId!=0) {
          let data = this.brandVoiceList.filter(item => { if (item.value == this.defaultBVId.toString()) return item })[0];
          if (data != null && data != undefined) {
            this.brandVoiceDetails = data;
            this.generateAiForm.patchValue(
              {
                brandVoiceDocument: data.brandVoiceDocument,
                brandVoiceDetails: data.details,
                brandVoiceId: +data.value
              }
            );
            this.selectedBrandVoice = data.value;
          }
        }
      }
      this.isLoading = false;
    }, (error: any) => {
      this.isLoading = false;
    });
  }

  fetchTargetAudiences(isReload: boolean = false) {
    if (isReload) this.targetAudienceList = [];
    var targetAudienceListRequest = new GetTargetAudienceListRequest();
    targetAudienceListRequest.profileId = +this._storageHelper.user.pId;

    this.isLoading = true;
    this.articleService.GetTargetAudienceList(targetAudienceListRequest).subscribe((response: GetTargetAudienceListResponse) => {
      console.log(response);
      if (response.errorCode != 0 && !response.errorMessage) {
        //show error messages
      } else {
        if (response.totalRecords > 0) this.isNoTargetAudience = false;
        response.targteAudiences.forEach((targetAudience: TargetAudience) => {
          if (targetAudience.isDefault) {
            this.isDefaultTAAvailable = true;
            this.defaultTAId = targetAudience.targetAudienceId;
          }
          this.targetAudienceList.push({
            "targetAudienceId": targetAudience.targetAudienceId,
            "title": targetAudience.title,
            "authorName": targetAudience.authorName,
            "thingTargetAudience": {
              "thingJson": {
                "title": targetAudience.title,
                "gender": targetAudience.thingTargetAudience.thingJson.gender,
                "ageGroup": targetAudience.thingTargetAudience.thingJson.ageGroup,
                "relationshipStatus": targetAudience.thingTargetAudience.thingJson.relationshipStatus,
                "industry": targetAudience.thingTargetAudience.thingJson.industry,
                "interests": targetAudience.thingTargetAudience.thingJson.interests,
                "intent": targetAudience.thingTargetAudience.thingJson.intent
              }
            },
            "createdDate": targetAudience.createdDate
          });
        });
        if (this.isDefaultTAAvailable && this.defaultTAId != 0) {
          let data = this.targetAudienceList.filter(item => { if (item.targetAudienceId == this.defaultTAId) return item })[0];
          if (data != null && data != undefined) {
            this.targetAudienceDetails = data;
            this.generateAiForm.patchValue(
              {
                targetAudienceDetails: data.thingTargetAudience.thingJson,
                targetAudienceId: +data.targetAudienceId
              }
            );
          }
          this.selectedTargetAudience = data.title;

        }
      }
      this.isLoading = false;
    }, (error: any) => {
      this.isLoading = false;
    });
  }

  private toTitleCase(text: string): string {
    return text.replace(/\b\w/g, (match) => match.toUpperCase());
  }

  public FillInterestAndPersona() {
    let targetAudiencesRequest = new GetAudiencesRequest();
    targetAudiencesRequest.ProfileId = this._storageHelper.user.pId;
    this.topicService.GetAudiences(targetAudiencesRequest).subscribe((response: GetAudiencesResponseType) => {
      if (response.audiences && response.audiences.length > 0) {
        response.audiences.forEach(element => {
          this.interestsPersonalsList.push({
            label: element,
            value: element
          });
        });
      }
    })
  }
  public generateArticle(isOutlineCreation: boolean) {
    this.templateHelperService.closeTemplateSelector();
    const flowType = this.isGenericFlow ? 'Generic Flow' : 'Content Intelligence Flow';
    const eventType = isOutlineCreation ? 'Generate Outline' : 'Generate Article';
    this.tracking.trackEvent(`pageview`,`${flowType} - ${eventType}`);
    if (this.selectedKeywords.length > 0) {
      let spinner = this.articleHelperService.showAiGenerationLoadingModal(this.modalService, this.modal, isOutlineCreation ? ErrorModalOrigins.FROM_TOPIC_IDEA_AI_OUTLINE_GENERATION_MODAL : ErrorModalOrigins.FROM_TOPIC_IDEA_AI_GENERATION_MODAL);
      this.isLoading = true;
      let language = this.provinceData.find((ele, index) => ele.value == this.generateAiForm.controls["language"].value).label;
      let topicIdeaPayloadForAIGeneration = new TopicIdeaPayloadForAIGeneration();
      topicIdeaPayloadForAIGeneration.topicIdea = this.mwcModalData.AIData.topicIdea;
      topicIdeaPayloadForAIGeneration.topicIdea.pillarIdea = this.generateAiForm.controls["description"].value ? this.generateAiForm.controls["description"].value : "";
      topicIdeaPayloadForAIGeneration.topicIdea.coreTopic = this.generateAiForm.controls["coreTopic"].value ? this.generateAiForm.controls["coreTopic"].value : "";
      topicIdeaPayloadForAIGeneration.topicIdea.keywords = this.selectedKeywords;
      topicIdeaPayloadForAIGeneration.contentBrief = this.generateAiForm.controls["contentBrief"].value ? this.generateAiForm.controls["contentBrief"].value : "";
      topicIdeaPayloadForAIGeneration.tone = this.generateAiForm.controls["tone"].value;
      topicIdeaPayloadForAIGeneration.language = language;
      topicIdeaPayloadForAIGeneration.gender = this.generateAiForm.controls["gender"].value;
      topicIdeaPayloadForAIGeneration.ageGroup = this.generateAiForm.controls["ageGroup"].value;
      topicIdeaPayloadForAIGeneration.interestAndPersona = this.generateAiForm.controls["interestsPersonals"].value;
      topicIdeaPayloadForAIGeneration.brandVoiceDocument = this.mwcModalData.AIData.brandVoiceDocument;
      topicIdeaPayloadForAIGeneration.isOutlineCreation = isOutlineCreation;
      topicIdeaPayloadForAIGeneration.isGenericFlow = this.isGenericFlow;
      topicIdeaPayloadForAIGeneration.templateId = this.selectedTemplateId;
      topicIdeaPayloadForAIGeneration.brandVoiceId = this.generateAiForm.controls["brandVoiceId"].value;
      topicIdeaPayloadForAIGeneration.brandVoiceDocument = this.generateAiForm.controls["brandVoiceDocument"].value;
      topicIdeaPayloadForAIGeneration.brandVoiceDetails = this.generateAiForm.controls["brandVoiceDetails"].value;
      topicIdeaPayloadForAIGeneration.targetAudienceId = this.generateAiForm.controls["targetAudienceId"].value;
      topicIdeaPayloadForAIGeneration.targetAudienceDetails = this.generateAiForm.controls["targetAudienceDetails"].value;
      topicIdeaPayloadForAIGeneration.templateTitle = this.selectedTemplateTitle;
      let langDetail = new DocumentLanguage();
      langDetail.languageName = language;
      langDetail.languageCode = this.provinceData.find((ele, index) => ele.value == this.generateAiForm.controls["language"].value).languageCode;;
      langDetail.countryCode = this.generateAiForm.controls["language"].value;
      topicIdeaPayloadForAIGeneration.languageDetail = langDetail;
      try {
            
        //save default BV and TA only if they changed
        if (this.isDefaultBVAvailable && this.selectedBrandVoice != this.defaultBVId.toString()) {
          var defaultBrandVoiceForProfileRequest = new DefaultBrandVoiceForProfileRequest();
          defaultBrandVoiceForProfileRequest.brandVoiceId = +this.selectedBrandVoice;
          defaultBrandVoiceForProfileRequest.businessId = +this._storageHelper.user.bId;
          defaultBrandVoiceForProfileRequest.profileIds = [this._storageHelper.user.pId];
          this.articleService.assignDefaultBrandVoiceForProfile(defaultBrandVoiceForProfileRequest).subscribe();
        }

        if (this.isDefaultTAAvailable && this.targetAudienceDetails!=null && this.targetAudienceDetails.targetAudienceId != this.defaultTAId) {
          var defaultTargetAudienceForProfileRequest = new DefaultTargetAudienceForProfileRequest();
          defaultTargetAudienceForProfileRequest.targetAudienceId = this.targetAudienceDetails.targetAudienceId;
          defaultTargetAudienceForProfileRequest.businessId = +this._storageHelper.user.bId;
          defaultTargetAudienceForProfileRequest.profileIds = [this._storageHelper.user.pId];
          this.articleService.assignDefaultTargetAudienceForProfile(defaultTargetAudienceForProfileRequest).subscribe();
        }

        // generate with AI
        this.articleService.generateContentByAIForTopicIdea(topicIdeaPayloadForAIGeneration).subscribe((response: any) => {
          try {
            if (!response.contentResponse.contents && response.contentResponse.errorCode > 0) {
              throw new Error(JSON.stringify(response.contentResponse.errorMessage));
            }
            // TODO: assign content to blog object here - only assigning body and title for now
            let postBody = response.contentResponse.contents[0].content;
            // const validJSON = postBody.replace(/([a-zA-Z0-9_]+?):/g, '"$1":');
            const parsedObject = JSON.parse(postBody);
            let article = new Article();
            article.isPublishable = this.isPublishable;
            article.title = parsedObject.PostTitle;
            article.content = parsedObject.BodySection;
            article.keyPhrase = this.selectedKeywords ? this.noTilde(this.selectedKeywords[0]) : "";
            article.metaTitle = parsedObject.MetaTitle;
            article.metaDescription = parsedObject.MetaDescription;
            article.snippet = parsedObject.PostSummary;
            article.slugUrl = this.noTilde(parsedObject.UrlSlug);
            article.tagsList = parsedObject.Tags;
            article.tone = this.generateAiForm.controls["tone"].value;
            article.language = this.generateAiForm.controls["language"].value;
            article.promptProperties = new PromptProperties();
            article.promptProperties.intent = topicIdeaPayloadForAIGeneration.topicIdea.intent;
            article.promptProperties.pillarIdea = topicIdeaPayloadForAIGeneration.topicIdea.pillarIdea;
            article.promptProperties.coreTopic = topicIdeaPayloadForAIGeneration.topicIdea.coreTopic;
            article.promptProperties.keywords = topicIdeaPayloadForAIGeneration.topicIdea.keywords.join();
            article.templateId = response.templateId;
            article.targetAudienceId = response.targetAudienceId;
            article.brandVoiceId = response.brandVoiceId;
            article.templateTitle = response.templateTitle;
            let docDetail = new DocumentDetail();
            docDetail.documentId = response.documentId;
            let languageCode = this.provinceData.find((ele) => ele.value == this.generateAiForm.controls["language"].value)?.languageCode;
            let languageName = this.provinceData.find((ele) => ele.value == this.generateAiForm.controls["language"].value)?.label;
            let countryCode = this.generateAiForm.controls["language"].value;
            let lang = new DocumentLanguage();
            lang.languageCode = languageCode;
            lang.languageName = languageName;
            lang.countryCode = countryCode;
            docDetail.documentLanguage = lang;
            if (!article.documentsDetail) {
              article.documentsDetail = [];
            }
            docDetail.status = 'done';
            article.documentsDetail.push(docDetail);
            this.articleStoreService.updateState(article);
            this.modal.destroy();
            spinner.close();
            this.isLoading = true;
            this.cdRef.detectChanges();
            this.router.navigateByUrl("/manage-content/articles/create-detailed-post/create");
          } catch (error) {
            spinner.close();
            this.isLoading = false;
            console.log(error);
            topicIdeaPayloadForAIGeneration.language = this.generateAiForm.controls["language"].value;
            this.articleHelperService.showErrorModal(this.modalService, this.modal, ErrorModalOrigins.FROM_TOPIC_IDEA_AI_GENERATION_MODAL, error, null, topicIdeaPayloadForAIGeneration)
          }
        }, (error: HttpErrorResponse) => {
          spinner.close();
          this.isLoading = false;
          console.log(error);
          topicIdeaPayloadForAIGeneration.language = this.generateAiForm.controls["language"].value;
          this.articleHelperService.showErrorModal(this.modalService, this.modal, ErrorModalOrigins.FROM_TOPIC_IDEA_AI_GENERATION_MODAL, error, null, topicIdeaPayloadForAIGeneration)
        })
      } catch (error) {
        spinner.close();
        this.isLoading = false;
        console.log(error);
        topicIdeaPayloadForAIGeneration.language = this.generateAiForm.controls["language"].value;
        this.articleHelperService.showErrorModal(this.modalService, this.modal, ErrorModalOrigins.FROM_TOPIC_IDEA_AI_GENERATION_MODAL, error, null, topicIdeaPayloadForAIGeneration)
      }
    }
    else {
      this.message.create('error', "Atleast one keyword is required content generation.");
    }
  }
  public onBriefSwitchChanged(event) {
    this.isContentBriefEnabled = event;
    this.cdRef.detectChanges();
  }
  validatetagTone(control: UntypedFormControl) {
    let isValidtone = control.value.length > 0;
    let isValid = !isValidtone;
    return isValid ? { isValidtone: true } : null;
  }
  toggelAdvancedSettings() {
    this.tracking.trackEvent('pageview','Advanced (Inside Prompt)');
    this.isadvancedSettingsShow = !this.isadvancedSettingsShow;
  }

  removeKeyword(removeKeyword: {}): void {
    this.selectedKeywords = this.selectedKeywords.filter(selectedKeyword => selectedKeyword !== removeKeyword);
  }

  sliceKeyword(selectedKeyword: string): string {
    const isLongKeyword = selectedKeyword.length > 20;
    return isLongKeyword ? `${selectedKeyword.slice(0, 20)}...` : selectedKeyword;
  }

  showKeywordInput(): void {
    this.keywordInputVisible = true;
    setTimeout(() => {
      this.inputElement?.nativeElement.focus();
    }, 10);
  }

  addKeywordToSelectedKeywords(): void {
    if (this.selectedKeywordInputValue && this.selectedKeywords.indexOf(this.selectedKeywordInputValue) === -1) {
      this.selectedKeywords = [...this.selectedKeywords, this.selectedKeywordInputValue];
    }
    this.selectedKeywordInputValue = '';
    this.keywordInputVisible = false;
  }

  selectYourKeywordModal(isLoading, spinner, resetCount): void {
    this.tracking.trackEvent('pageview','Generic Flow - Proceed to Keyword Selection');
    // call ai generation loading modal here 
    if (resetCount) {
      this.retryCount = 0;
    }
    if (!isLoading) {
      spinner = this.articleHelperService.showAiGenerationLoadingModal(this.modalService, null, ErrorModalOrigins.FROM_KEYPHRASE_GENERATION_MODAL);
      this.isLoading = true;
    }
    try {
      let tagTone = this.generateAiForm.get("tone").value;
      let language = this.provinceData.find((ele, index) => ele.value == this.generateAiForm.get("language").value).label;
      let aiEngine = this.generateAiForm.get("aiEngine").value;
      // call an api to extract keyphrases
      this.articleService.generateKeyphrasesFromContext(this.generateAiForm.get("description").value, tagTone, language, aiEngine).subscribe((aiResponse: any) => {
        try {
          if (!aiResponse.keyphraseResponse.contents && aiResponse.keyphraseResponse.errorCode > 0) {
            throw new Error(JSON.stringify(aiResponse.keyphraseResponse.errorMessage));
          }
          // Modify the string to make it valid JSON - it will add double quotes to key 
          const validJSON = aiResponse.keyphraseResponse.contents[0].content.replace(/([a-zA-Z0-9_]+?):/g, '"$1":');
          const parsedObject = JSON.parse(validJSON);
          // call google keyword planner api to fetch keywords
          this.getKeyphraseFromGoogleKeywordsPlanner(parsedObject.Keyphrases, spinner);
        } catch (error) {
          spinner.close();
          this.isLoading = false;
          console.log(error);
          // this.msg.error(error, { mwcAnimate: true, mwcDuration: 10000 });
          // show error modal if kyephrase generation is failed
          this.articleHelperService.showErrorModal(this.modalService, this.modal, ErrorModalOrigins.FROM_KEYPHRASE_GENERATION_MODAL, error)
        }
      }, (error: HttpErrorResponse) => {
        spinner.close();
        this.isLoading = false;
        console.log(error);
        // this.msg.error(error.message, { mwcAnimate: true, mwcDuration: 10000 });
        // show error modal if kyephrase generation is failed
        this.articleHelperService.showErrorModal(this.modalService, this.modal, ErrorModalOrigins.FROM_KEYPHRASE_GENERATION_MODAL, error)
      })
    } catch (error) {
      spinner.close();
      this.isLoading = false;
      console.log(error);
      // this.msg.error(error, { mwcAnimate: true, mwcDuration: 10000 });
      // show error modal if kyephrase generation is failed
      this.articleHelperService.showErrorModal(this.modalService, this.modal, ErrorModalOrigins.FROM_KEYPHRASE_GENERATION_MODAL, error)
    }
  }



  public getKeyphraseFromGoogleKeywordsPlanner(keyphrase: string, spinner: any): void {
    const customError: CustomArticleErrorModel = {
      ErrorHeading: $localize`:@@bs-create-new-modal-gkp-error-heading-text:Please Review Your Prompt`,
      ErrorSubHeading: $localize`:@@bs-create-new-modal-gkp-error-subheading-text:The system is unable to find relevant keyphrases based on the prompt you have selected. Kindly try again with a different prompt.`
    }
    let tagTone = this.generateAiForm.get("tone").value;
    let language = this.provinceData.find((ele, index) => ele.value == this.generateAiForm.get("language").value).label;
    this.articleService.getKeyphraseFromGoogleKeywordsPlanner(keyphrase).subscribe((keywordResponse: GoogleKeywordPlannerResponseType) => {
      try {
        //this.modal.destroy();
        if (keywordResponse.results == null) {
          throw new Error("Keywords not found for keyphrase");
        }
        this.isLoading = false;
        spinner.close();
        let modalRef = this.modalService.create({
          mwcWidth: '900px',
          mwcContent: SelectYourKeywordComponent,
          mwcData: {
            inputContext: this.generateAiForm.get("description").value,
            keywordResponse,
            tagTone: tagTone,
            language: language,
            aiEngine: this.generateAiForm.get("aiEngine").value,
            keywords: this.selectedKeywords
          },
          mwcCentered: true,
          mwcClassName: 'select-keyword'
        });
        modalRef.componentInstance.confirmEmitter.subscribe(res => {
          this.selectedKeywords = Array.from(new Set([...this.selectedKeywords, ...res]));;
          modalRef.close();
        });
        this.retryCount = 0;
      } catch (error) {
        if (this.retryCount < this.maxRetryCount) {
          this.retryCount++;
          this.selectYourKeywordModal(true, spinner, false);
        }
        else {
          spinner.close();
          this.isLoading = false;
          console.log(error);
          // this.msg.error(error, { mwcAnimate: true, mwcDuration: 10000 });
          // show error modal if kyephrase generation is failed
          this.articleHelperService.showErrorModal(this.modalService, null, ErrorModalOrigins.FROM_KEYPHRASE_GENERATION_MODAL, error, customError)
        }
      }
    }, (error: HttpErrorResponse) => {
      if (this.retryCount < this.maxRetryCount) {
        this.retryCount++;
        this.selectYourKeywordModal(true, spinner, false);
      }
      else {
        spinner.close();
        this.isLoading = false;
        console.log(error);
        // this.msg.error(error, { mwcAnimate: true, mwcDuration: 10000 });
        // show error modal if kyephrase generation is failed
        this.articleHelperService.showErrorModal(this.modalService, null, ErrorModalOrigins.FROM_KEYPHRASE_GENERATION_MODAL, error, customError)
      }
    });
  }

  onAddPersona(value: boolean) {
    this.personaEnabled = value;
  }

  onSavePersona(value: boolean) {
    this.tracking.trackEvent('pageview','Custom Persona Added');
    this.personaEnabled = value;
    if (this.addedPersona) {
      let updateAudienceRequest = new UpdateAudienceRequest();
      updateAudienceRequest.profileId = this._storageHelper.user.pId;
      updateAudienceRequest.audienceName = this.addedPersona;
      this.settingsService.updateAudience(updateAudienceRequest).subscribe((response: GetAudiencesResponseType) => {
        if (response.audiences && response.audiences.length > 0) {
          response.audiences.forEach(element => {
            this.interestsPersonalsList.push({
              label: element,
              value: element
            });
          });
          this.cdRef.detectChanges();
        }
      });
    }
    this.addedPersona = '';
  }

  noTilde(s) {
    if (s.normalize != undefined) {
      s = s.normalize("NFKD");
    }
    return s.replace(/[\u0300-\u036F]/g, "");
  }

  languageChanged(lang) {
    this.tracking.trackEvent('pageview','Selection: Language');
  }

  toneChanged(tone) {
    this.tracking.trackEvent('pageview','Selection: Tone');
  }
  public openPromptRepository() {
    window.open("https://milestone-inc.helpjuice.com/content-generator-use-cases-prompt-guide-", "_blank");
  }

  //UpdateTextByContent(contentTitle: string): { titleText: string, placeholderText: string, contentBriefText: string } {
  //  var titleText = "";
  //  var placeholderText = "";
  //  var contentBriefText = "";
  //  switch (contentTitle) {
  //    case "Blog Article":
  //    case "HowTo Post":
  //    case "Listicle":
  //    case "Press Release":
  //    case "News Article":        
  //    case "NewsLetter":      
  //    case "Facebook Post":
  //    case "LinkedIn Post":
  //    case "Instagram Captions":
  //    case "Twitter Post":
  //    case "Google GMB Post":
  //    case "Apple Post":
  //    case "HowTo Video Script":
  //    case "Video Ideas":
  //    case "Podcast Script":
  //    case "Webinar Script":
  //       titleText = "What is the topic of your " + contentTitle;
  //       placeholderText = "Enter the topic of your " + contentTitle;
  //      switch (contentTitle) {
  //        case "Blog Article":
  //        case "HowTo Post":
  //        case "Listicle":
  //        case "Facebook Post":
  //        case "LinkedIn Post":
  //        case "Instagram Captions":
  //        case "Twitter Post":
  //        case "Google GMB Post":
  //        case "Apple Post":
  //        case "Press Release":
  //          contentBriefText = "Do you have a content brief";
  //          break;
  //        case "News Article":
  //        case "NewsLetter":
  //          contentBriefText = "Objective of the " + contentTitle;
  //          break;
  //        case "HowTo Video Script":
  //        case "Video Ideas":
  //          contentBriefText = "Do you have a Video Information Details";
  //          break;
  //        case "Podcast Script":
  //          contentBriefText = "Do you have a Podcast Objective";
  //          break;
  //        case "Webinar Script":
  //          contentBriefText = "Do you have a Webinar Objective";
  //          break;
  //        default:
  //          contentBriefText = "Do you have a content brief";
  //          break;
  //      }         
  //       return { titleText, placeholderText, contentBriefText };
  //      break;
  //    case "Quora Answers":
  //      titleText = "What is the question asked";
  //      placeholderText = "Enter the question to be asked ";
  //      contentBriefText = "What is the objective of the answer you want to include";
  //      return { titleText, placeholderText, contentBriefText };
  //      break;
  //    case "Search Ads":
  //    case "Display Ads":
  //    case "Email Sales Pitch":
  //      titleText = "What is the product / service you need to advertise";
  //      placeholderText = "Enter the product / service you need to advertise";
  //      contentBriefText = "Provide product / service features to showcase";
  //      return { titleText, placeholderText, contentBriefText };
  //      break;
  //    default:
  //       titleText = "What is the topic of your " + contentTitle;
  //       placeholderText = "Enter the topic of your " + contentTitle;
  //       contentBriefText = "Do you have a content brief";
  //      return { titleText, placeholderText, contentBriefText };
  //      break;
  //  }
  //}
  createBrandVoice(){
    this.isNoCreateBrandVoice = false;
    window.open(this.brandVoiceUrl + this._storageHelper.user.bId);
  }
  toggleContent() {
    this.isExpanded = !this.isExpanded;
    this.updateTextareaScroll();
  }

  private updateTextareaScroll() {
    const textarea = this.contentTextarea?.nativeElement;
    if (textarea != undefined) {
      if (!this.isExpanded) {
        textarea.scrollTo({ top: 0, behavior: 'smooth' });
        textarea.style.overflow = 'hidden';
      } else {
        textarea.style.overflow = 'auto';
      }
    }
  }
  brandVoiceChange(event){
   let data = this.brandVoiceList.filter(item => { if(item.value == event) return item})[0];
    if (data != null && data != undefined) {
      this.brandVoiceDetails = data;
      this.generateAiForm.patchValue(
        {
          brandVoiceDocument: data.brandVoiceDocument,
          brandVoiceDetails:data.details,
          brandVoiceId:+data.value
        }
      );
    }
  }

  createTargetAudience(){
    this.isNoTargetAudience = false;
    window.open(this.targetAudienceUrl + this._storageHelper.user.bId);
  }
  targetAudienceChange(event){
    let data = this.targetAudienceList.filter(item => { if (item.title == event) return item })[0];
    if (data != null && data != undefined) {
      this.targetAudienceDetails = data;
      this.generateAiForm.patchValue(
        {
          targetAudienceDetails: data.thingTargetAudience.thingJson,
          targetAudienceId: +data.targetAudienceId
        }
      );
    }
  }
  getDaysDifference(inputDate) {
    // Convert input date to a Date object
    const date = new Date(inputDate);
    // Get today's date
    const today = new Date();

    // Reset time components to compare only dates
    today.setHours(0, 0, 0, 0);
    date.setHours(0, 0, 0, 0);

    // Calculate difference in milliseconds
    const timeDifference = Math.abs(today.getTime() - date.getTime());

    // Convert milliseconds to days
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

    // Format the result
    if (daysDifference === 0) {
      return "Today";
    } else if (daysDifference === 1) {
      return "1 day ago";
    } else {
      return `${daysDifference} days ago`;
    }
  }
  ageMapObject(value){
    let foundObject  = ageList.find(obj => obj.value === value)
    if(foundObject)
      return foundObject.label;
    else
      return null; 
  }
  openRedirectToMPC(event){
    this.isRedirectToMPC = true;
    this.modalType = event;
  }

  brandVoiceSearch(event: any) {
    console.log(event);
    if (event && event != null) {
      this.filteredBrandVoiceList = this.brandVoiceList.filter(s => s.tone.toLowerCase().includes(event.toLowerCase()) || s.label.toLowerCase().includes(event.toLowerCase()));
      this.filteredBrandVoiceList = [...this.filteredBrandVoiceList];
    } else {
      this.filteredBrandVoiceList = this.brandVoiceList;
    }
    this.cdRef.detectChanges();
  }

  reloadList(event:string) {
    if (event == "targetAudience") {
      this.fetchTargetAudiences(true);
    } else {
      this.fetchBrandVoices(true);
    }
  }
  redirectToMPCModalCancel(): void {
    this.isRedirectToMPC = false;
  }
  redirectToMPCModalOk(): void {
    this.isRedirectToMPC = false;
    if (this.modalType == 'targetAudience') {
      window.open(this.targetAudienceUrl + this._storageHelper.user.bId);
    } else {
      window.open(this.brandVoiceUrl + this._storageHelper.user.bId);
    }
  }

  openCustomerPortal(actionType: string) {
    this.commonService.openCustomerPortal(actionType).subscribe(
      (stripeUrl: string) => {
        if (stripeUrl) {
          window.location.href = stripeUrl;
        }
      },
      (error) => {
        console.error('Error occurred:', error);
      }
    );
  }
}
