import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, lastValueFrom, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { User, VerifyUserRequest } from 'src/app/_shared/models/common';
import { StorageHelper } from 'src/app/_utilities/storage-helper';
import { Router } from '@angular/router';
import { log } from 'mwc/util/other';
import { MixpanelService } from 'src/app/_shared/services/mixpanel/mixpanel.service';

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  private tokenUrl = 'api/auth/InitializeUserSession';
  private newTokenUrl = 'api/auth/getnewtoken';
  private tokenSubject = new BehaviorSubject<string | null>(null);
  private expiryTimestampSubject = new BehaviorSubject<number | null>(null);
  private isAuthenticatedSubject = new BehaviorSubject<boolean>(false);

  token$ = this.tokenSubject.asObservable();
  expiryTimestamp$ = this.expiryTimestampSubject.asObservable();
  isAuthenticated$ = this.isAuthenticatedSubject.asObservable();
  mixpanelTrackingId: string = '';
  private _storageHelper: StorageHelper;

  constructor(private http: HttpClient, private router: Router, @Inject('BASE_URL') private baseUrl: string,
    private mixpanelService: MixpanelService) {
    this._storageHelper = new StorageHelper();
    // Attempt to restore token from storage
    const storedToken = this._storageHelper.user?.accessToken;
    const storedExpiry = this._storageHelper.user?.expiryTimestamp;

    if (storedToken && storedExpiry && storedExpiry > Date.now()) {
      this.setToken(storedToken, (storedExpiry - Date.now()) / 1000);
    } else {
      this.clearToken();
    }
  }

  async fetchTokenFromApi(request: VerifyUserRequest): Promise<User | null> {
    try {
      let header = new HttpHeaders({ 'Content-Type': 'application/json' });
      const user = await this.http.post<User>(this.baseUrl + this.tokenUrl, request, {
        withCredentials: true,
        headers: header,
      }).toPromise();
      if (user && user.accessToken) {
        this.setToken(user.accessToken);
        this.storeUserData(user);
        this._storageHelper.user = {
          ...this._storageHelper.user
        };
        await this.getMixpanelInfo();
        this.router.navigateByUrl(this.router.url);
      } else {
        this.clearToken();
        this.routeToBase()
      }
      return user;
    } catch (error) {
      this.clearToken();
      console.error('Failed to fetch token:', error);
      return error;
    }
  }

  private async getMixpanelInfo() {
    if (!this.mixpanelTrackingId || this.mixpanelTrackingId === '') {
      const result = this.mixpanelService.getMixpanelId();
      const trackingId = await lastValueFrom(result);
      if (trackingId) {
        this.mixpanelTrackingId = trackingId.mixpanelTrackingId;
        window.mixpanel.init(this.mixpanelTrackingId);
      }
    }
  }

  private storeUserData(user: User): void {
    let expTimeStamp = this._storageHelper.user.expiryTimestamp;
    // Store user-related information using the StorageHelper
    this._storageHelper.user = user;
    this._storageHelper.user = {
      ...this._storageHelper.user,
      expiryTimestamp: expTimeStamp,  // Storing expiry time in StorageHelper
    };
  }

  setToken(token: string, expiresIn: number = 3600): void {
    const currentTime = new Date().getTime();
    const expiryTime = currentTime + expiresIn * 1000;

    this.tokenSubject.next(token);
    this.expiryTimestampSubject.next(expiryTime);
    this.isAuthenticatedSubject.next(true);
    // Store token and expiry in StorageHelper
    this._storageHelper.user = {
      ...this._storageHelper.user,
      expiryTimestamp: expiryTime,  // Storing expiry time in StorageHelper
    };
  }

  clearToken(): void {
    this.tokenSubject.next(null);
    this.expiryTimestampSubject.next(null);
    this.isAuthenticatedSubject.next(false);
  }

  getTokenValue(): string | null {
    return this.tokenSubject.getValue();
  }

  getExpiryTimestampValue(): number | null {
    return this.expiryTimestampSubject.getValue();
  }

  isTokenValid(): boolean {
    const currentTime = new Date().getTime();
    const expiryTimestamp = this.getExpiryTimestampValue();
    return !!expiryTimestamp && expiryTimestamp > currentTime;
  }

  getNewAccessToken(): Observable<any | null> {
    let header = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post<any>(this.baseUrl + this.newTokenUrl, '', {
      withCredentials: true,
      headers: header,
    }).pipe(
      tap(token => {
        this.setToken(token.access_token);
        this._storageHelper.user.accessToken = token.access_token;
      }),
      catchError(() => {
        this.clearToken();
        return of(null);
      })
    );
  }

  routeToBase(url:string="") {
    const redirectUrl = this._storageHelper.user ? (this._storageHelper.user.returnUrl != "" ? this._storageHelper.user.returnUrl : url) : url;
    this.router.navigate(['auth'], {
      queryParams: { redirectUrl: redirectUrl },
    });
  }
}
